import React from "react";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

export const Background = () => {
	const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

	const particlesConfig = {
		particles: {
			number: {
				value: 250,
				density: {
					enable: true,
					value_area: 800
				}
			},
			color: {
				value: "#ffffff"
			},
			shape: {
				type: "circle",
				stroke: {
					width: 0,
					color: "#000000"
				},
				polygon: {
					nb_sides: 5
				},
				image: {
					src: "img/github.svg",
					width: 100,
					height: 100
				}
			},
			opacity: {
				value: 1,
				random: true,
				anim: {
					enable: true,
					speed: 1,
					opacity_min: 0,
					sync: false
				}
			},
			size: {
				value: 3,
				random: true,
				anim: {
					enable: false,
					speed: 4,
					size_min: 0.3,
					sync: false
				}
			},
			line_linked: {
				enable: true,
				distance: 80,
				color: "#ffffff",
				opacity: 0.6,
				width: 1
			},
			move: {
				enable: true,
				speed: 0.5,
				direction: "none",
				random: true,
				straight: false,
				out_mode: "out",
				bounce: false,
				attract: {
					enable: false,
					rotateX: 600,
					rotateY: 600
				}
			}
		},
		interactivity: {
			detect_on: "canvas",
			events: {
				onhover: {
					enable: true,
					mode: "grab"
				},
				onclick: {
					enable: false,
					mode: "repulse"
				},
				resize: true
			},
			modes: {
				grab: {
					distance: 100,
					line_linked: {
						opacity: 1
					}
				},
				bubble: {
					distance: 120,
					size: 0,
					duration: 2,
					opacity: 1,
					speed: 1
				},
				repulse: {
					distance: 60,
					duration: 0.4
				},
				push: {
					particles_nb: 4
				},
				remove: {
					particles_nb: 2
				}
			}
		},
		retina_detect: true
	};

	return (
		<>
			<Particles
				params={particlesConfig}
				init={particlesInit}
				onloaded={particlesLoaded}
				style={{
					top: 0,
					left: 0,
					position: "absolute",
					height: "100%",
					width: "100%"
				}}
			/>
		</>
	);
}
